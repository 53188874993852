'use strict';

var formValidation = require('sfra/components/formValidation');
var createErrorNotification = require('sfra/components/errorNotification');
var appointmentSlider = require('@baseJs/project/components/appointmentSlider');
var formHelpers = require('../../checkout/formErrors');
var DOBInputUtils = require('../../components/DOBInputUtils');

var isMobileOrTablet = window.matchMedia('only screen and (max-width: 1024px)').matches;
var isTriggeredByMarker = false;

const STORE_SEARCH_FORM_SELECTOR = isMobileOrTablet ? '[data-js="appointment-stores-form-mobile"]' : '[data-js="appointment-stores-form"]';
const POSTCODE_INPUT_SELECTOR = isMobileOrTablet ? '[data-js="appointment-stores-search-mobile"]' : '[data-js="appointment-stores-search"]';
const MY_LOCATION_BUTTON_SELECTOR = isMobileOrTablet ? '[data-js="my-location-button-mobile"]' : '[data-js="my-location-button"]';
const SAVE_TIMESLOT_FORM_SELECTOR = isMobileOrTablet ? '[data-js="appointment-save-timeslot-form-mobile"]' : '[data-js="appointment-save-timeslot-form"]';
const STORE_RESULTS_CONTAINER_SELECTOR = isMobileOrTablet ? '[data-js="appointment-stores-results-mobile"]' : '[data-js="store-locator-results"]';
const ERROR_RESULTS_CONTAINER_SELECTOR = isMobileOrTablet ? '[data-js="appointment-error-results-mobile"]' : '[data-js="appointment-error-results"]';

const $appointmentContainer = $('[data-js="appointment-container"]');
const $registrationActivatedInput = $appointmentContainer.find('[name="registrationActivated"]');
const $additionalRegisterFormGroup = $appointmentContainer.find('[data-js="additional-register-form"]');

if ($('[data-js="map-canvas"]').length > 0 && $('[data-js="map-canvas"]').attr('data-has-google-api')) {
    var customGoogleMap = require('../googlemap/customMap');
    $(document).ready(function () {
        customGoogleMap.initGoogleMap(findSelectStore);
        customGoogleMap.initAutocomplete();
    });
}

function findSelectStore(storeId, postalCode, isTriggeredFromMarker) {
    isTriggeredByMarker = isTriggeredFromMarker;
    var $selectedStoreContainer = $('[data-js="store-container"][data-id="' + storeId + '"]');
    if ($selectedStoreContainer.length > 0) {
        selectStore($selectedStoreContainer);
    } else {
        findStore(postalCode);
    }
}

function selectStore($selectedStoreContainer) {
    if (!$selectedStoreContainer.hasClass('is-open')) {
        $selectedStoreContainer.find('[data-map-event="marker-click"]').trigger('click');
    }
    if ($selectedStoreContainer.hasClass('is-hidden')) {
        $('[data-js="show-more-stores"]').trigger('click');
    }
    $([document.documentElement, document.body]).animate({
        scrollTop: $selectedStoreContainer.offset().top - 100
    }, 600);

    // add custom event for appointemnt store block view tracking
    var event = new CustomEvent('storeBlockViewEvent', {
        detail: $selectedStoreContainer
    });
    document.dispatchEvent(event);
}

function findStore(postalCode) {
    var zipcodeCompact = postalCode.trim().replace(' ', '');
    $(POSTCODE_INPUT_SELECTOR).val(zipcodeCompact);
    $('[data-js="appointment-container"]').find(STORE_SEARCH_FORM_SELECTOR).trigger('submit');
}

function getSchedule(selector, data, behaviourInstructions) {
    var $selectedStoreContainer = selector.closest('[data-js="store-container"]');
    var $scheduleContainer = $selectedStoreContainer.find('[data-js="appointment-store-schedule"]');
    var $dailyScheduleContainer = $selectedStoreContainer.find('[data-js="appointment-store-daily-schedule"]');

    $.ajax({
        url: $selectedStoreContainer.data('url'),
        type: 'GET',
        dataType: 'json',
        data: data,
        success: function (response) {
            $selectedStoreContainer.find('[data-js="appointment-store-schedule"]').empty();
            $selectedStoreContainer.find('[data-js="appointment-store-daily-schedule"]').empty();
            $selectedStoreContainer.find('[data-js="select-timeslot-button"]').hide();
            $selectedStoreContainer.find('[data-js="toggle-timeslots"]').hide();
            if (response.error) {
                $scheduleContainer.append(response.errorMessage);
                if (customGoogleMap) {
                    customGoogleMap.selectMarker(data.storeId);
                }
            } else {
                $scheduleContainer.append(response.storeScheduleHtml);
                selectFirstStoreWithAvailableTimeslots($scheduleContainer, $dailyScheduleContainer);
                appointmentSlider.init();
                if (customGoogleMap) {
                    customGoogleMap.selectMarker(data.storeId);
                }

                $selectedStoreContainer.trigger('appointmentSchedule:loaded', [data, response.storeScheduleObject, behaviourInstructions]);
            }
            // Check if initial schedule date is already there
            if (!$scheduleContainer.attr('data-initial-schedule-date')) {
                $scheduleContainer.attr('data-initial-schedule-date', response.firstAvailableDay);
            }
            $selectedStoreContainer.spinner().stop();
        },
        error: function (err) {
            $selectedStoreContainer.find('[data-js="appointment-store-schedule"]').empty();
            $selectedStoreContainer.find('[data-js="appointment-store-daily-schedule"]').empty();
            $selectedStoreContainer.find('[data-js="select-timeslot-button"]').hide();
            $selectedStoreContainer.find('[data-js="toggle-timeslots"]').hide();
            $selectedStoreContainer.spinner().stop();
        }
    });
}

function initSchedule(selector, behaviourInstructions) {
    var $getScheduleButton = selector;

    if ($getScheduleButton.hasClass('appointment-stores__option-btn')) {
        $getScheduleButton.prop('disabled', true);
    }

    var $selectedStoreContainer = $getScheduleButton.closest('[data-js="store-container"]');
    var $scheduleContainer = $selectedStoreContainer.find('[data-js="appointment-store-schedule"]');

    $selectedStoreContainer.siblings().removeClass('is-open');
    $selectedStoreContainer.siblings().find('[data-js="appointment-store-schedule"]').empty();
    $selectedStoreContainer.siblings().find('[data-js="appointment-store-daily-schedule"]').empty();
    $selectedStoreContainer.siblings().find('[data-js="select-timeslot-button"]').hide();
    $selectedStoreContainer.siblings().find('[data-js="toggle-timeslots"]').hide();
    $selectedStoreContainer.siblings().find('[data-js="get-schedule-button"]').prop('disabled', false);

    $selectedStoreContainer.addClass('is-open');
    $selectedStoreContainer.spinner().start();

    $('[name="storeId"]').val($selectedStoreContainer.data('id'));

    var $storeId = $('[name="storeId"]').val();
    var startScheduleDate = $(STORE_RESULTS_CONTAINER_SELECTOR).data('start-schedule-date');

    var initialDate = $scheduleContainer.attr('data-initial-schedule-date');

    var data = {
        storeId: $storeId,
        appointmentCode: $('[name="appointmentCode"]').val(),
        typeOfAppointment: $('[name="typeOfAppointment"]').val(),
        appointmentServiceId: $('[name="appointmentServiceId"]').val(),
        startScheduleDate: startScheduleDate,
        initialDate: initialDate
    };

    if ($getScheduleButton.data('start-schedule-date')) {
        data.startScheduleDate = $getScheduleButton.data('start-schedule-date');
        if ($getScheduleButton.data('prev')) {
            data.requestPrevious = $getScheduleButton.data('prev');
        }
        if ($getScheduleButton.data('next')) {
            data.requestNext = $getScheduleButton.data('next');
            // if next button is clicked we need to save the previous schedule's start day
            data.previousSchedule = $scheduleContainer.find('[data-js="store-schedule-day"]').first().data('date');
        }
    }

    getSchedule(selector, data, behaviourInstructions);
}

function selectFirstStoreWithAvailableTimeslots($scheduleContainer, $dailyScheduleContainer) {
    var scheduleDays = $scheduleContainer.find('[data-js="store-schedule-day"]');
    var firstAvailableDay = scheduleDays.filter(':not(.is-unavailable)').first();

    if (firstAvailableDay.length > 0) {
        firstAvailableDay.trigger('click');
        $dailyScheduleContainer.find('[data-js="store-schedule-timeslot"]').first().trigger('click');
    } else {
        scheduleDays.filter('.is-unavailable').first().click();
    }
}

$('[data-js="appointment-container"]').on('change', '[name="questionnaire"]', function (e) {
    var $selectedOption = $(e.target);
    var parameterValue = '';

    var $nextStepButton = $('[data-js="appointment-next-step"]');
    var $storeSelectionButton = $('[data-js="appointment-store-selection"]');
    var $actionButton;

    if ($selectedOption.data('code')) {
        parameterValue = '?code='.concat($selectedOption.data('code'));
        $actionButton = $storeSelectionButton;

        $nextStepButton.addClass('is-hidden');
        $storeSelectionButton.removeClass('is-hidden');
    } else {
        parameterValue = '?type='.concat($selectedOption.val());
        $actionButton = $nextStepButton;

        $nextStepButton.removeClass('is-hidden');
        $storeSelectionButton.addClass('is-hidden');
    }

    var url = $actionButton.data('href');
    var baseUrl = url.split('?')[0];

    var newUrl = baseUrl.concat(parameterValue);

    $actionButton.data('href', newUrl).prop('disabled', false);
});

$('[data-js="appointment-container"]').on(
    'click',
    '[data-js="appointment-answer"]',

    function (e) {
        if (this.checked) {
            $(this).parents()
                .find('input[type=radio]').not(this)
                .attr('checked', false);
        }

        $(e.currentTarget).attr('checked', 'checked');
    }
);

$('[data-js="appointment-container"]').on(
    'click',
    '[data-js="appointment-next-step"], [data-js="appointment-prev-step"], [data-js="appointment-store-selection"]',

    function (e) {
        e.preventDefault();
        window.location.href = $(this).data('href');
    }
);

$('[data-js="appointment-container"]').on('click', MY_LOCATION_BUTTON_SELECTOR, function (e) {
    e.preventDefault();
    $('[data-postal=api-search-result]').val('');

    var $myLocationBtn = $(this);
    // Check if the button is already disabled to prevent multiple triggers
    if ($myLocationBtn.prop('disabled')) return;
    $myLocationBtn.prop('disabled', true);
    $(STORE_SEARCH_FORM_SELECTOR).find(POSTCODE_INPUT_SELECTOR).val('');

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            // success callback function
            $(STORE_SEARCH_FORM_SELECTOR).find('[name="lat"]').val(position.coords.latitude);
            $(STORE_SEARCH_FORM_SELECTOR).find('[name="long"]').val(position.coords.longitude);
            $('[data-js="appointment-container"]').find(STORE_SEARCH_FORM_SELECTOR).trigger('submit', 'mylocation');
        }, function () {
            // error callback function - use location from backend (IP address)
            $(STORE_SEARCH_FORM_SELECTOR).find('[name="lat"]').val($myLocationBtn.data('mylocation')['lat']);
            $(STORE_SEARCH_FORM_SELECTOR).find('[name="long"]').val($myLocationBtn.data('mylocation')['long']);
            $('[data-js="appointment-container"]').find(STORE_SEARCH_FORM_SELECTOR).trigger('submit', 'mylocation');
        });
    } else {
        // customer disabled browser location - use location from backend (IP address)
        $(STORE_SEARCH_FORM_SELECTOR).find('[name="lat"]').val($myLocationBtn.data('mylocation')['lat']);
        $(STORE_SEARCH_FORM_SELECTOR).find('[name="long"]').val($myLocationBtn.data('mylocation')['long']);
        $('[data-js="appointment-container"]').find(STORE_SEARCH_FORM_SELECTOR).trigger('submit', 'mylocation');
    }
});

$('[data-js="appointment-container"]').on('keypress', POSTCODE_INPUT_SELECTOR, function (e) {
    if (e.key === 'Enter') {
        e.preventDefault();
        var $form = $(this);
        $('[data-postal=api-search-result]').val('');
        $('[data-js="appointment-container"]').find(STORE_SEARCH_FORM_SELECTOR).trigger('submit');
        if ($form.prop('disabled')) return;
        $form.prop('disabled', true);
    }
});

$(document).ready(function () {
    if ($(POSTCODE_INPUT_SELECTOR).val()) {
        $('[data-js="appointment-container"]').find(STORE_SEARCH_FORM_SELECTOR).trigger('submit');
    }

    DOBInputUtils.repopulateDOBInputs($('[data-js="appointment-customer-form"]'));
});

$('[data-js="appointment-container"]').on('submit', STORE_SEARCH_FORM_SELECTOR, function (e, triggeredFrom) {
    e.preventDefault();
    e.stopImmediatePropagation();
    var $myLocationBtn = $('[data-js="appointment-container"]').find(MY_LOCATION_BUTTON_SELECTOR);
    $(STORE_RESULTS_CONTAINER_SELECTOR).empty();

    var $form = $(e.target);
    var $postalCodeInput = $form.find(POSTCODE_INPUT_SELECTOR);
    var isMyLocationSearch = triggeredFrom === 'mylocation';

    var data = $form.serialize();

    $.ajax({
        url: $form.attr('action'),
        type: 'GET',
        dataType: 'json',
        data: data,
        success: function (response) {
            if (response.error) {
                $(STORE_RESULTS_CONTAINER_SELECTOR).html(response.errorMessage);
            } else {
                // Remove focus from the input on mobile
                $postalCodeInput.blur();

                // gtm event start
                var gtmData = {
                    location: $postalCodeInput.val(),
                    activityType: 'search'
                };
                $(document).trigger('gtm:storeFinder', gtmData);
                // gtm event end
                if (response.stores.length === 0) {
                    $(STORE_RESULTS_CONTAINER_SELECTOR).append('<p>' + $(STORE_RESULTS_CONTAINER_SELECTOR).data('errors').noStoreFound + '</p>');
                } else if ($postalCodeInput.val() !== '' || $form.find('[name="lat"]').val() !== '') {
                    $(STORE_RESULTS_CONTAINER_SELECTOR).append(response.storesResultsHtml);
                    var $firstStoreResult = $(STORE_RESULTS_CONTAINER_SELECTOR).find('[data-js="store-container"]').first();
                    initSchedule($firstStoreResult);
                }
                $(STORE_RESULTS_CONTAINER_SELECTOR).data('has-results', 'true').data('radius', response.radius).data('search-key', response.searchKey);

                if (isMobileOrTablet) {
                    // recalculate the zoom level for picked store on mobile devices
                    $('[data-js="map-canvas"]').attr('data-locations', response.locations);
                    customGoogleMap.initGoogleMap(findSelectStore);
                    $('[data-js="map-canvas"]').addClass('is-hidden');
                    return;
                }

                $('[data-js="map-canvas"]').attr('data-locations', response.locations);
                if ($('[data-js="map-canvas"]').data('has-google-api')) {
                    // eslint-disable-next-line block-scoped-var
                    customGoogleMap.initGoogleMap(findSelectStore, true, isMyLocationSearch);
                } else {
                    $('[data-js="store-locator-no-apiKey"]').show();
                }
            }
        },
        error: function (err) {
            if (err.responseJSON.errorMessage) {
                createErrorNotification(ERROR_RESULTS_CONTAINER_SELECTOR, err.responseJSON.errorMessage);
            }
        },
        complete: function () {
            $myLocationBtn.prop('disabled', false);
            $postalCodeInput.prop('disabled', false);
        }
    });
});

$('[data-js="appointment-container"]').on('click', '[data-js="get-schedule-button"]', function (e, behaviourInstructions) {
    e.preventDefault();
    e.stopImmediatePropagation();

    var $scheduleButton = $(this);

    if ($scheduleButton.hasClass('appointment-stores__option-btn')) {
        $scheduleButton.prop('disabled', true);
    }

    if (!isMobileOrTablet) {
        initSchedule($scheduleButton, behaviourInstructions);
    } else if ($scheduleButton.hasClass('appointment-stores__option-btn')
        || $scheduleButton.hasClass('end-reached')
        || ($scheduleButton.hasClass('has-prev-week')
            && $scheduleButton.hasClass('beginning-reached'))
        || (behaviourInstructions && behaviourInstructions.forceNextScheduleOnMobile)) {
        initSchedule($scheduleButton, behaviourInstructions);
    }
});


$('[data-js="appointment-container"]').on('click', '[data-js="store-schedule-day"]', function (e) {
    e.preventDefault();
    var $currentDay = $(this);

    $currentDay.addClass('is-selected');
    $currentDay.siblings().removeClass('is-selected');

    var $selectedStoreContainer = $currentDay.closest('[data-js="store-container"]');
    $selectedStoreContainer.find('[data-js="select-timeslot-button"]').css({ display: 'none' });
    $('[name="appointmentDay"]').val($currentDay.data('date'));

    $('[data-js="appointment-store-daily-schedule"]').empty();

    var timeslots = $currentDay.data('timeslots');

    var timeslotsHtml = '';
    var timeslotsCaption = $currentDay.closest('[data-js="store-schedule"]').data('msg-select-time');

    if (timeslots.length === 0) {
        timeslotsCaption = $currentDay.data('msg-no-timeslots');
    }

    timeslots.forEach(function (timeslot) {
        timeslotsHtml += '<button type="button" data-js="store-schedule-timeslot"';
        if (timeslot.roomId) {
            timeslotsHtml += ` data-roomid="${timeslot.roomId}"`;
        }
        if (timeslot.employeeId) {
            timeslotsHtml += ` data-employeeid="${timeslot.employeeId}"`;
        }
        timeslotsHtml += `>${timeslot.time}</button>`;
    });

    $selectedStoreContainer.find('[data-js="appointment-store-daily-schedule"]').append(
        '<div class="appointment-stores__option-time">'
        + `<span class="appointment-stores__option-label">${timeslotsCaption}</span>`
        + `<div class="appointment-stores__option-time-buttons is-flex">${timeslotsHtml}</div>`
        + '</div>'
    );

    var $dailyScheduleContainer = $selectedStoreContainer.find('[data-js="appointment-store-daily-schedule"]');
    $dailyScheduleContainer.find('[data-js="store-schedule-timeslot"]').first().trigger('click');
});

$('[data-js="appointment-container"]').on('click', '[data-js="store-schedule-timeslot"]', function (e) {
    e.preventDefault();
    $(this).addClass('is-selected');
    $(this).siblings().removeClass('is-selected');

    $('[name="appointmentTime"]').val($(this).text());
    $('[name="appointmentRoomId"]').val($(this).data('roomid'));
    $('[name="appointmentEmployeeId"]').val($(this).data('employeeid'));

    var $selectedStoreContainer = $(this).closest('[data-js="store-container"]');
    $selectedStoreContainer.find('[data-js="select-timeslot-button"]').css({ display: 'block' });
});

$('[data-js="appointment-container"]').on('click', '[data-js="select-timeslot-button"]', function (e) {
    e.preventDefault();

    var $form = $(SAVE_TIMESLOT_FORM_SELECTOR);
    if ($form.length === 0) {
        // mobile form may not be available on all appointments page, use desktop as fallback
        $form = $('[data-js="appointment-save-timeslot-form"]');
    }
    var $selectedStoreContainer = $(this).closest('[data-js="store-container"]');

    var $errorContainer = $selectedStoreContainer.find('[data-js="appointment-error"]');
    $errorContainer.empty();
    $errorContainer.spinner().start();

    DOBInputUtils.saveDOBInputs($(this));

    $.ajax({
        url: $form.attr('action'),
        type: 'POST',
        dataType: 'json',
        data: $form.serialize(),
        success: function (response) {
            if (response && response.redirectUrl) {
                window.location.href = response.redirectUrl;
            }
            if (response && response.error) {
                $errorContainer.spinner().stop();
                createErrorNotification($errorContainer, response.errorMessage);
            }
        },
        error: function (err) {
            $errorContainer.spinner().stop();
            if (err.responseJSON.errorMessage) {
                createErrorNotification($errorContainer, err.responseJSON.errorMessage);
            }
        }
    });
});

$('[data-js="appointment-container"]').on('click', '[data-js="show-more-stores"]', function (e) {
    e.preventDefault();

    var lastVisibleIndex = $('[data-js="store-container"]').index($('[data-js="store-container"]:not(".is-hidden")').last());
    var numberOfStores = $('[data-js="store-container"]').length;
    var lastElementCounter;
    var elementByIndex;

    if (lastVisibleIndex === numberOfStores - 1) {
        return;
    }

    if (lastVisibleIndex < 3) {
        for (let index = lastVisibleIndex + 1; index < 10; index++) {
            elementByIndex = $('[data-js="store-container"]').eq(index);
            if (elementByIndex.length === 1) {
                elementByIndex.removeClass('is-hidden');
                lastElementCounter = index + 1;
            }
        }
        $('[data-js="appointment-stores-progress-line"]').css({ width: ((lastElementCounter / numberOfStores) * 100).toString() + '%' });
        $('[data-js="appointment-stores-progress-text"]').text(function () {
            return $(this).text().replace(' ' + (lastVisibleIndex + 1) + ' ', ' ' + lastElementCounter + ' ');
        });
        if (numberOfStores === lastElementCounter) {
            $(this).addClass('is-hidden');
        }
    } else {
        for (let index = lastVisibleIndex + 1; index < lastVisibleIndex + 11; index++) {
            elementByIndex = $('[data-js="store-container"]').eq(index);
            if (elementByIndex.length === 1) {
                elementByIndex.removeClass('is-hidden');
                lastElementCounter = index + 1;
            }
        }
        $('[data-js="appointment-stores-progress-line"]').css({ width: ((lastElementCounter / numberOfStores) * 100).toString() + '%' });
        $('[data-js="appointment-stores-progress-text"]').text(function () {
            return $(this).text().replace(' ' + (lastVisibleIndex + 1) + ' ', ' ' + lastElementCounter + ' ');
        });
        if (numberOfStores === lastElementCounter) {
            $(this).addClass('is-hidden');
        }
    }
});

$('[data-js="appointment-container"]').on('submit', '[data-js="appointment-customer-form"]', function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    var $form = $(this);

    var $errorContainer = $('[data-js="appointment-container"]').find('[data-js="appointment-error"]');

    $errorContainer.empty();
    $errorContainer.spinner().start();

    DOBInputUtils.saveDOBInputs($(this));

    $.ajax({
        url: $form.attr('action'),
        type: 'POST',
        dataType: 'json',
        data: $form.serialize(),
        success: function (response) {
            if (response && response.redirectUrl) {
                window.location.href = response.redirectUrl;
            }
            $errorContainer.spinner().stop();
            if (response && response.error) {
                if (response.fieldErrors && response.fieldErrors.length) {
                    response.fieldErrors.forEach(function (error) {
                        if (Object.keys(error).length) {
                            formHelpers.loadFormErrors('[data-js="appointment-customer-form"]', error);
                        }
                    });
                }
                if (response.errorMessage) {
                    createErrorNotification($errorContainer, response.errorMessage);
                }
            }
        },
        error: function (err) {
            $errorContainer.spinner().stop();
            if (err.responseJSON.message) {
                createErrorNotification($errorContainer, err.responseJSON.message);
            }
        }
    });
});

$appointmentContainer.on('click', '[data-js="activate-registration-form"]', () => {
    if ($registrationActivatedInput.val() === 'true') {
        disableAdditionalInputs();
        $registrationActivatedInput.val('false');
    } else {
        enableAdditionalInputs();
        $registrationActivatedInput.val('true');
    }
});

$('[data-js="appointment-container"]').on('click', '[data-js="delete-appointment-button"]', function (e) {
    e.preventDefault();
    var $element = $(this);
    var $appointmentNumber = $('[name="appointmentNumber"]').val();
    var $errorContainer = $('[data-js="appointment-store"]').find('[data-js="appointment-error"]');

    $errorContainer.empty();

    $.ajax({
        url: $element.attr('href'),
        type: 'GET',
        dataType: 'json',
        data: {
            appointmentNumber: $appointmentNumber
        },
        success: function (response) {
            $('#modal .close').click();
            if (response && response.redirectUrl) {
                window.location.href = response.redirectUrl;
            }
            if (response && response.error) {
                createErrorNotification($errorContainer, response.errorMessage);
            }
        },
        error: function (err) {
            $('#modal .close').click();
            if (err.responseJSON.errorMessage) {
                createErrorNotification($errorContainer, err.responseJSON.errorMessage);
            }
        }
    });
});

$('[data-js="appointment-webform"]').submit(function (event) {
    event.preventDefault();

    const $form = $(this);
    $form.spinner().start();
    $.ajax({
        url: $form.attr('action'),
        type: 'post',
        dataType: 'json',
        data: $form.serialize(),
        success: function (data) {
            if (!data.success) {
                formValidation($form, data);
                $form.spinner().stop();
            } else {
                if (data.gtmData && $('#add-to-email-list').is(':checked')) {
                    window.dataLayer.push(data.gtmData);
                }
                window.location.href = data.redirectUrl;
            }
        },
        error: function (err) {
            $form.spinner().stop();
            createErrorNotification($('.submit-error'), err.responseJSON.errorMessage);
        }
    });
});

function disableAdditionalInputs() {
    $additionalRegisterFormGroup.find('input, select').prop('disabled', true);
}

function enableAdditionalInputs() {
    $additionalRegisterFormGroup.find('input, select').removeAttr('disabled', true);
}

function init() {
    disableAdditionalInputs();
}

module.exports = {
    init
};
